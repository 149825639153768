import { render, staticRenderFns } from "./about.vue?vue&type=template&id=adef7146"
import script from "./about.vue?vue&type=script&lang=js"
export * from "./about.vue?vue&type=script&lang=js"
import style0 from "./about.vue?vue&type=style&index=0&id=adef7146&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports